.ant-spin-spinning {
  display: block !important;
}

.createRadioGroup {
  display: flex !important;
  justify-content: right;
  margin-bottom: 25px !important;
}

.uploadLabel{
  display: flex !important;
  justify-content: center;
  font-weight: 700;
}

.ant-menu-item-active .logo {
  width: 140px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
  text-align: center;
  line-height: 2.3;
  color: white;
  border-radius: 5px;
  border-color: white;
  font-weight: 250;
  background: rgba(255, 255, 255, 0.2);
}

li.ant-menu-overflow-item:nth-child(1) {
  //height: 31px;
  //vertical-align: middle;
  margin-right: 2em;
}

.ant-menu-item .logo {
  width: 140px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
  text-align: center;
  line-height: 2.3;
  color: white;
  border-radius: 5px;
  font-weight: 250;
}

.ant-modal-title {
  padding-right: 20px;
}

.disabledMenu {
  display: none;
}

.ant-menu-overflow {
  display: block;
}

.ant-menu-submenu-open span {
  color:rgba(255, 255, 255) !important;
}

li.ant-menu-overflow-item:nth-last-child(2) {
  float: right;
}

#mainMenu\$Menu, #adminMenu\$Menu {
  padding-left: 200px;
  background: white;
  color: rgb(63, 63, 63);
  margin-top: -10px;
  display: flex;
  height: 50px;

  .ant-menu-item-group-title {
      color: rgb(63, 63, 63);
  } 
  
  li.ant-menu-item-active {
    border-bottom: 2px solid rgb(39, 174, 243) !important;

    a {
      color: rgb(39, 174, 243) !important;
    }
  }

  .ant-menu-item {
    padding-top: 10px;
    margin-top: 10px;
    line-height: 0px;

      a {
          color: rgb(63, 63, 63);
      }
  }
}
.arrow_box {
  position: relative;
  padding-bottom: 5px;
}

.arrow_box:after, .arrow_box:before {
  transform: rotate(180deg);
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
  pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(0, 0, 0, 0);
	border-top-color: white;
	border-width: 10px;
	margin-left: -10px;
}

.searchDropdownDiv, .customControls {
  background-color: #f0f2f5;
  padding-top: 15px;
  padding-bottom: 15px;

  .searchDropdown {
    padding: 10px;
  }

  .selectTitle, .customTitle {
    font-weight: 600;
    margin-left: 1em;
  }
  .cutoffTitle {
    font-weight: 600;
    font-size: 1.1em;
    margin-left: 4em;
    margin-bottom: 1em;
  }
}

.chemical-structure-svg {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.svg-container {
  height: 450px !important;
}

.align-search {
  display: flex;
  justify-content: space-around;
}

.protein-img, .compound-img {
  max-width: 500px;
}

#data>div {
  margin-top: 2.5em;
}

#data th, #data td {
  padding: 0.25em 0.5em;
}

.filterDropdown {
  display: block;
  margin: 0.5em;
}
